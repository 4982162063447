import { useEffect, useState } from "react";



function App() {
  const [ id, setId ] = useState(null);

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const currentId = url.searchParams.get("id")
    currentId && setId(currentId)
  }, []);

  return (
    <div className="App">
      { id && <iframe width="560" height="315" src={`https://www.youtube.com/embed/${id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen autoplay="1"></iframe>}
      { id && <iframe src={`https://www.youtube.com/live_chat?v=${id}&embed_domain=${window.location.host}`} frameborder="0"></iframe> }
      { !id && `no youtube id` }
    </div>
  );
}

export default App;
